<template>
  <b-modal
    id="user-modal"
    :title="
      readonly
        ? 'عرض بيانات المستخدم'
        : form.id
        ? 'تحديث بيانات المستخدم'
        : 'أضافة مستخدم'
    "
    shadow
    hide-footer
    :no-close-on-backdrop="true"
    @hidden="clearForm"
    size="lg"
    body-class="p-5"
  >
    <div>
      <VForm v-slot="{ onValidate }">
        <VFileField
          v-model="form.image"
          label="الصورة الشخصيه"
          type=".jpg, .png"
          :disabled="readonly"
        ></VFileField>
        <b-row>
          <b-col>
            <VTextField
              v-model="form.name"
              label="أسم المستخدم"
              rules="required"
              :readonly="readonly"
            ></VTextField
          ></b-col>
          <b-col
            ><VTextField
              v-model="form.phone"
              label="رقم الهاتف"
              rules="required"
              :readonly="readonly"
              style="direction: ltr"
            ></VTextField
          ></b-col>
        </b-row>
        <b-row>
          <b-col
            ><VTextField
              v-model="form.email"
              label="البريد الألكتروني"
              rules="required"
              :readonly="readonly"
            ></VTextField
          ></b-col>
          <b-col
            ><VTextField
              v-model="form.password"
              type="password"
              label="كلمة المرور"
              :rules="form.id ? null : 'required'"
              :readonly="readonly"
            ></VTextField
          ></b-col>
        </b-row>
        <b-row v-if="!readonly" class="pt-4">
          <b-col>
            <b-button
              :variant="form.id ? 'success' : 'info'"
              block
              @click="onValidate(action)"
              >{{ form.id ? "تحديث" : "أضافة" }}</b-button
            >
          </b-col>
          <b-col>
            <b-button
              variant="outline-info"
              block
              @click="$bvModal.hide('user-modal')"
              >الغاء</b-button
            >
          </b-col>
        </b-row>
      </VForm>
    </div>
  </b-modal>
</template>

<script>
import { clearObject, successAlert, errorAlert } from "@/utils/helper";
export default {
  props: { readonly: Boolean },
  computed: {
    form: {
      get() {
        return this.$store.state.admin.users.form;
      },
      set(value) {
        this.$store.commit("admin/users/SET_USER", value);
      },
    },
  },
  methods: {
    action() {
      var state = this.form.id ? "update" : "store";
      this.$store
        .dispatch(`admin/users/${state}`)
        .then((response) => {
          successAlert();
          this.$bvModal.hide("user-modal");
          this.$store.commit(
            `admin/users/${state.toUpperCase()}_USER`,
            response.payload
          );
        })
        .catch((error) =>
          errorAlert(
            error.response ? error.response.data.message : "حدثت مشكله"
          )
        );
    },
    clearForm() {
      this.form = clearObject(this.form);
      this.$emit("close");
    },
  },
};
</script>

<style></style>
